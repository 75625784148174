import React, { useState, useContext, useEffect } from "react";
import $, { isNumeric } from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import StepMenu from "../stepMenu";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

import { toast } from "react-hot-toast";
import { Spin, Select } from "antd";

import ConfirmModal from "../../confirmModal";
import {
  groupByMultiple,
  lettersOnly,
  lowerCase,
  momentDate,
  numberToMonth,
  integerKeyPress,
  upperCase,
} from "../../../utils";
import { COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY } from "../../../utils/data";
import { Link } from "react-router-dom";
import { listUniversity } from "../../../models/fieldSettings";
const { Option } = Select;

const StepTwo = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [captcha, setCaptcha] = useState([]);
  const [applicant, setApplicant] = useState([]);
  const [hscSubjects, setHscSubjects] = useState([]);

  const [minMark, setMinMarks] = useState(70);
  const [maxMark, setMaxMark] = useState(200);
  const [community, setCommunity] = useState("");
  const [subjectCount, setSubjectCount] = useState(0);
  const [board, setBoard] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [savedMarks, setSavedMarks] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  const [ugDegree, setUgDegree] = useState([]);
  const [ugDegreeList, setUgDegreeList] = useState([]);
  const [ugMajor, setUgMajor] = useState([]);
  const [studiedUg, setStudiedUg] = useState(null);
  const [universityName, setUniversityName] = useState("");
  const partName = (part) => {
    return part == 1
      ? "Language (Part I)"
      : part == 2
      ? "English (Part II)"
      : part == 3
      ? "Major (Part III)"
      : part == 4
      ? "Allied 1 (Part III - For reference only)"
      : part == 5
      ? "Allied 2 (Part III - For reference only)"
      : part == 6
      ? "Part IV"
      : part == 7
      ? "Part V"
      : "";
  };

  const getMarksInfo = (part) => {
    return {
      subject_id: part,
      subjectname: partName(part),
      mark_scored: "",
      mark_maximum: "",
      pass_month: 4,
      pass_year: new Date().getFullYear(),
      register_no: "",
      class_obtained: "",
      no_of_attempts: "",
      scored_total: "",
      max_total: "",
      part3_total: "",
      percentage_of_marks: "",
      overall_percentage: "",
      no_of_attempts: "",
    };
  };

  const [marks, setMarks] = useState([]);

  useEffect(() => {
    loadApplicantInfo();
    loadUgForPg();

    listUniversity().then((res) => res && setUniversityList(res));
    if (props.pageType && props.pageType == "edit") loadData();
  }, []);

  const loadData = () => {
    setDataLoader(true);
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.PG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        let d = res["data"].data.basic;
        let m = res["data"].data.marks;
        setBaisc(res["data"].data.basic);
        setSavedMarks(res["data"].data.marks);
        setCommunity(d.community);
        setBoard(d.board);

        let mr = [];
        m.map((item, i) => {
          mr.push({
            id: item.id,
            subject_id: item.subject_id,
            subjectname: item.subject_name,
            mark_scored: parseFloat(item.mark_scored),
            mark_maximum: parseFloat(item.mark_maximum),
            pass_month: item.pass_month,
            pass_year: item.pass_year,
            register_no: item.register_no,
            no_of_attempts: item.no_of_attempts,
            scored_total: item.scored_total,
            max_total: item.max_total,
            part3_total: item.part3_total,
            percentage_of_marks: item.percentage_of_marks,
          });
        });

        setMarks(mr);
        //if (m[0]["studied_degree"]) {
        //handleStudiedDegreeChange(m[0]["studied_degree"]);
        setStudiedUg(m[0]["studied_major"]);
        //}

        setDataLoader(false);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };
  const handleEditClick = () => {
    $("#subjectmark").hide();
    $("#subjectmarktotal").hide();
    $("#part3mark").show();
  };
  const handleButtonClick = () => {
    if (
      !$("#sem1_percentage").val() ||
      !$("#sem3_percentage").val() ||
      !$("#sem3_percentage").val() ||
      !$("#sem4_percentage").val() ||
      !$("#sem5_percentage").val()
    ) {
      $("#savebtn").click();
      //document.getElementById("frmStepII").submit();
    } else {
      var totalmark =
        parseFloat($("#sem1_mark_scored").val()) +
        parseFloat($("#sem2_mark_scored").val()) +
        parseFloat($("#sem3_mark_scored").val()) +
        parseFloat($("#sem4_mark_scored").val()) +
        parseFloat($("#sem5_mark_scored").val()) +
        parseFloat(
          $("#sem6_mark_scored").val() ? $("#sem6_mark_scored").val() : 0
        );
      var totalmax =
        parseFloat($("#sem1_max_scored").val()) +
        parseFloat($("#sem2_max_scored").val()) +
        parseFloat($("#sem3_max_scored").val()) +
        parseFloat($("#sem4_max_scored").val()) +
        parseFloat($("#sem5_max_scored").val()) +
        parseFloat(
          $("#sem6_max_scored").val() ? $("#sem6_max_scored").val() : 0
        );
      console.log(totalmax);
      let m = [...marks];
      let index = m.findIndex((obj) => obj.subject_id == 3);
      if (index > -1) {
        m[index]["mark_scored"] = totalmark;
        m[index]["mark_maximum"] = totalmax;
        setMarks(m);
        updtePercentage();
      }

      $("#part3mark").hide();
      $("#subjectmark").show();
      $("#subjectmarktotal").show();
    }
  };

  // $("#part3submition").on("click", handleButtonClick);

  const marksField = (fieldName) => {
    if (savedMarks && savedMarks.length > 0 && savedMarks[0][fieldName])
      return savedMarks[0][fieldName];
  };

  useEffect(() => {
    //if (!props.pageType && props.pageType != "edit") {
    {
      let m = [];
      for (let i = 1; i <= 7; i++) {
        m.push(getMarksInfo(i));
      }
      setMarks(m);
    }
  }, []);

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.PG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setCommunity(d.community);
          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const loadUgForPg = () => {
    try {
      setLoader(true);
      setUgDegree([]);
      setUgDegreeList([]);
      axios.get(ServiceUrl.PG.UG_FOR_PG + "?type=pg").then((res) => {
        if (res["data"].status == "1") {
          var d = groupByMultiple(res["data"].data, function (obj) {
            return [obj.ugdegree];
          });
          setUgDegree(d);
          setUgDegreeList(res["data"].data);
        }
      });
      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (props.pageType && props.pageType == "edit") {
      if (savedMarks.length > 0)
        handleStudiedDegreeChange(savedMarks[0]["studied_degree"]);
    }
  }, [ugDegree, savedMarks]);

  const handleStudiedDegreeChange = (v) => {
    try {
      var d = ugDegreeList;
      var s = d.filter(function (obj) {
        return obj.ugdegree == v;
      });
      setUgMajor(s);
    } catch (error) {}
  };

  const handleMajorChange = (e) => {
    try {
      var v = e.target.value;
      var d = ugDegreeList;

      var index = d.findIndex(function (obj) {
        return obj.id == v;
      });

      if (index > -1) setStudiedUg(d[index].ugmajor);
    } catch (erro) {}
  };

  const days = () => {
    let rv = [];
    Array.from({ length: 31 }, (v, i) => {
      rv.push(<option value={i + 1}>{i + 1}</option>);
    });
    return rv;
  };

  const months = (sel) => {
    let rv = [];
    Array.from({ length: 12 }, (v, i) => {
      let n = ("0" + (i + 1)).slice(-2);
      rv.push(
        <option
          value={("0" + n).slice(-2)}
          selected={sel == i + 1 ? "selected" : ""}
        >
          {numberToMonth(n)}
        </option>
      );
    });
    return rv;
  };

  const years = (sel) => {
    let rv = [];
    Array.from({ length: 10 }, (v, i) => {
      let n = new Date().getFullYear() - i;
      rv.push(
        <option value={n} selected={sel == n ? "selected" : ""}>
          {n}
        </option>
      );
    });
    return rv;
  };

  const marksInputOnBlur1 = (e) => {};
  const maxMarksInputOnBlur1 = (item, i, e) => {
    if (parseFloat(e) < parseFloat($("#sem" + i + "_mark_scored").val())) {
      toast.error("Maximum mark not less than scored mark");
      $("#" + item).val("");
      return;
    }

    let p =
      (parseFloat($("#sem" + i + "_mark_scored").val()) / parseFloat(e)) * 100;
    if (p) {
      $("#sem" + i + "_percentage").val(parseFloat(p).toFixed(2));
    } else {
      $("#sem" + i + "_percentage").val("");
    }
  };

  const maxMarksInputOnBlur2 = (item, i, e) => {
    if (item == "12_percentage") {
      if (parseFloat(e) < parseFloat($("#12_mark_scored").val())) {
        toast.error("Maximum mark not less than scored mark");
        $("#" + item).val("");
        return;
      }

      let p = (parseFloat($("#12_mark_scored").val()) / parseFloat(e)) * 100;
      if (p) {
        $("#12_percentage").val(parseFloat(p).toFixed(2));
      } else {
        $("#12_percentage").val("");
      }
    } else {
      if (parseFloat(e) < parseFloat($("#10_mark_scored").val())) {
        toast.error("Maximum mark not less than scored mark");
        $("#" + item).val("");
        return;
      }

      let p = (parseFloat($("#10_mark_scored").val()) / parseFloat(e)) * 100;
      if (p) {
        $("#10_percentage").val(parseFloat(p).toFixed(2));
      } else {
        $("#10_percentage").val("");
      }
    }
  };

  const marksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    /* if (
      parseFloat(v) < parseFloat(ds.min) ||
      parseFloat(v) > parseFloat(ds.max)
    ) {
      toast.error("Enter correct mark");
      e.target.value = "";
      return;
    }*/
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index]["mark_scored"] = v;
      setMarks(m);
      //updtePercentage();
    }
  };

  const maxMarksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    /* if (
      parseFloat(v) < parseFloat(ds.min) ||
      parseFloat(v) > parseFloat(ds.max)
    ) {
      toast.error("Enter correct mark");
      e.target.value = "";
      return;
    }*/
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      let mark = m[index]["mark_scored"];
      if (parseFloat(v) < parseFloat(mark)) {
        toast.error("Maximum mark not less than scored mark");
        e.target.value = "";
        return;
      }
      m[index]["mark_maximum"] = v;
      setMarks(m);
      //updtePercentage();
    }
  };

  useEffect(() => {
    if (marks.length > 0) updtePercentage();
  }, [JSON.stringify(marks)]);

  const updtePercentage = () => {
    let m = [...marks];
    m.map((item, i) => {
      try {
        if (
          item.mark_scored &&
          parseFloat(item.mark_scored) > 0 &&
          item.mark_maximum &&
          parseFloat(item.mark_maximum) > 0
        ) {
          let p =
            (parseFloat(item.mark_scored) / parseFloat(item.mark_maximum)) *
            100;

          if (p) {
            item.percentage_of_marks = parseFloat(p).toFixed(2);
          } else {
            item.percentage_of_marks = "";
          }
        }
      } catch (er) {}
    });
    setMarks(m);
  };

  const getSubjectOptions = () => {
    let rv = [];
    hscSubjects.map((item, i) => {
      let index = marks.findIndex((obj) => obj.subject_id == item.id);
      //if (index == -1) rv.push(<Option value={item.id}>{item.name}</Option>);
      if (index == -1) rv.push({ value: item.id, label: item.name });
    });
    return rv;
  };

  const handleSubjectChange = (index, v) => {
    let m = [...marks];
    m[index]["subject_id"] = v;
    setMarks(m);
  };

  const handleInputChange = (item, fieldName, e) => {
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index][fieldName] = e.target.value;
      setMarks(m);
    }
  };

  const getTotalByFieldName = (fieldName) => {
    let m = marks;
    let total = 0;
    m.map((item, i) => {
      let v = item[fieldName];
      if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
    });
    return parseFloat(total);
  };

  const getOverallpercentage = (fieldName) => {
    let m = marks;
    let total = 0;
    let scored = 0;
    m.map((item, i) => {
      if (item.mark_scored && parseFloat(item.mark_scored) > 0)
        scored = parseFloat(scored) + parseFloat(item.mark_scored);
      if (item.mark_maximum && parseFloat(item.mark_maximum) > 0)
        total = parseFloat(total) + parseFloat(item.mark_maximum);
    });
    let p = (parseFloat(scored) / parseFloat(total)) * 100;

    return p && isNumeric(p) ? parseFloat(p).toFixed(2) : 0;
  };

  const part3Total = (fieldName) => {
    let m = marks;
    let total = 0;
    let s = m.find((item) => item.subject_id == 3);
    /* let a1 = m.find((item) => item.subject_id == 4);
    let a2 = m.find((item) => item.subject_id == 5); */
    if (s && s[fieldName]) {
      return parseFloat(s[fieldName]);
      /*            +
    parseFloat(a1[fieldName] ? a1[fieldName] : 0) +
        parseFloat(a2[fieldName] ? a2[fieldName] : 0) */
    } else return 0;
  };

  const part3Percentage = (fieldName) => {
    let m = marks;
    let total = 0;
    let s = m.find((item) => item.subject_id == 3);
    /*  let a1 = m.find((item) => item.subject_id == 4);
    let a2 = m.find((item) => item.subject_id == 5); */
    if (s && s.percentage_of_marks) {
      let percentage = parseFloat(s["mark_scored"]); /* +
        parseFloat(a1["mark_scored"] ? a1["mark_scored"] : 0) +
        parseFloat(a2["mark_scored"] ? a2["mark_scored"] : 0); */
      let totalmaxmark = parseFloat(s["mark_maximum"]); /* +
        parseFloat(a1["mark_maximum"] ? a1["mark_maximum"] : 0) +
        parseFloat(a2["mark_maximum"] ? a2["mark_maximum"] : 0); */
      return parseFloat((percentage / totalmaxmark) * 100).toFixed(2);
    } else return 0;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      toast.error("Please fill all the required fields.");
      return;
    }

    let m = marks.find((item) => !item.subject_id);
    if (m) {
      toast.error("Please select all the subjects and marks");
      return;
    }

    setShowConfirm(true);
  };

  const getUniversityOptions = () => {
    let rv = [];
    universityList.map((item, i) => {
      rv.push({ value: item.name, label: item.name });
    });
    return rv;
  };

  const handleUniversityChange = (v) => {
    setUniversityName(v);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);

    let url = ServiceUrl.PG.STEP_TWO;
    if (props.pageType && props.pageType == "edit")
      url = ServiceUrl.PG.UPDATE_STEP_TWO;

    axios.post(url, $("#frmStepII").serialize()).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");

        context.setLoader(false);
        setLoader(false);
        loadCaptcha();
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <StepMenu activeKey="step2" pageType={props.pageType} />

            {dataLoader && (
              <div className="text-center my-5">
                <Spinner />
              </div>
            )}

            {!dataLoader && (
              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Spin spinning={loader}>
                    <Form
                      noValidate
                      validated={validated}
                      encType="multipart/form-data"
                      id="frmStepII"
                      method="post"
                      onSubmit={handleFormSubmit}
                    >
                      <input
                        type="hidden"
                        name="i__userId"
                        value={context.user.user_id}
                      />
                      <input
                        type="hidden"
                        name="i__userCode"
                        value={context.user.user_code}
                      />
                      <input
                        type="hidden"
                        name="i__apiCode"
                        value={context.user.api_code}
                      />

                      <input
                        type="hidden"
                        name="i__studiedUg"
                        value={studiedUg}
                      />

                      <input
                        type="hidden"
                        name="marks"
                        value={JSON.stringify(marks)}
                      />

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Details of Qualification</h5>
                        </Col>
                      </Row>
                      <table
                        width="100%"
                        className="table table-sm table-bordered mt-2"
                      >
                        <thead>
                          <tr className="bg-light">
                            <th width="100">Class</th>
                            <th>Register No</th>
                            <th width="130">Mark Obtained</th>
                            <th width="200">Max Mark</th>
                            <th width="200">Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="fw-bold" align="center">
                              X
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="10_register_no"
                                className="fw-bold text-uppercase"
                                placeholder="Register No"
                                defaultValue={marksField("10_register_no")}
                                //onKeyPress={(e) => integerKeyPress(e)}
                                maxLength={30}
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="10_mark_scored"
                                id="10_mark_scored"
                                className="fw-bold text-uppercase"
                                placeholder="Mark Scored"
                                maxLength="4"
                                defaultValue={marksField("10_mark_scored")}
                                onKeyPress={(e) => integerKeyPress(e)}
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="10_max_mark"
                                id="10_max_mark"
                                className="fw-bold text-uppercase"
                                placeholder="Maximum Mark"
                                maxLength="4"
                                defaultValue={marksField("10_max_mark")}
                                onKeyPress={(e) => integerKeyPress(e)}
                                onBlur={(e) =>
                                  maxMarksInputOnBlur2(
                                    "10_percentage",
                                    1,
                                    e.target.value
                                  )
                                }
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="10_percentage"
                                id="10_percentage"
                                className="fw-bold text-uppercase"
                                placeholder="Percentage"
                                maxLength="4"
                                defaultValue={marksField("10_percentage")}
                                size="sm"
                                required
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-bold" align="center">
                              XII
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="12_register_no"
                                className="fw-bold text-uppercase"
                                placeholder="Register No"
                                defaultValue={marksField("12_register_no")}
                                //onKeyPress={(e) => integerKeyPress(e)}
                                maxLength={30}
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="12_mark_scored"
                                id="12_mark_scored"
                                className="fw-bold text-uppercase"
                                placeholder="Mark Scored"
                                maxLength="4"
                                defaultValue={marksField("12_mark_scored")}
                                onKeyPress={(e) => integerKeyPress(e)}
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="12_max_mark"
                                id="12_max_mark"
                                className="fw-bold text-uppercase"
                                placeholder="Maximum Mark"
                                maxLength="4"
                                defaultValue={marksField("12_max_mark")}
                                onKeyPress={(e) => integerKeyPress(e)}
                                onBlur={(e) =>
                                  maxMarksInputOnBlur2(
                                    "12_percentage",
                                    1,
                                    e.target.value
                                  )
                                }
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="12_percentage"
                                id="12_percentage"
                                className="fw-bold text-uppercase"
                                placeholder="Percentage"
                                maxLength="4"
                                value={marksField("12_percentage")}
                                size="sm"
                                required
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            College Previously studied
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__collegeName"
                            size="sm"
                            defaultValue={marksField("college_name")}
                            placeholder="College Previously studied"
                            onKeyPress={(e) => lettersOnly(e)}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Place of the College Previously studied
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="college_place"
                            size="sm"
                            placeholder="Place of the College"
                            defaultValue={marksField("college_place")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Name of the University
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="hidden"
                            name="i__universityName"
                            value={universityName}
                          />
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select"
                            optionFilterProp="children"
                            className="text-uppercase fw-bold"
                            filterOption={(input, option) =>
                              lowerCase(option?.label ?? "").includes(
                                lowerCase(input)
                              )
                            }
                            onChange={(v) => handleUniversityChange(v)}
                            defaultValue={marksField("university_name")}
                            options={getUniversityOptions()}
                            allowClear
                            required
                          ></Select>

                          {/* <Form.Control
                            as="select"
                            name="i__universityName"
                            defaultValue={marksField("university_name")}
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            required
                          >
                            <option value="">-Select-</option>
                            {universityList &&
                              universityList.length > 0 &&
                              universityList.map((item) => (
                                <option
                                  value={item.name}
                                  selected={
                                    marksField("university_name") == item.name
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                          </Form.Control> */}

                          {/* <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__universityName"
                            size="sm"
                            placeholder="Name of the University"
                            defaultValue={marksField("university_name")}
                            required
                          /> */}
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Studied Degree & Major
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__studiedDegree"
                            onChange={(e) =>
                              handleStudiedDegreeChange(e.target.value)
                            }
                            required
                          >
                            <option value=""> -Select- </option>
                            {ugDegree.map((item, i) => {
                              return (
                                <option
                                  value={item[0].ugdegree}
                                  selected={
                                    item[0].ugdegree ==
                                    marksField("studied_degree")
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {item[0].ugdegree}{" "}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__studiedMajor"
                            onChange={(e) => handleMajorChange(e)}
                            required
                          >
                            <option value=""> -Select- </option>
                            {ugMajor.map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  data-id={item.id}
                                  selected={
                                    item.id == marksField("studied_ug")
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {upperCase(item.ugmajor)}{" "}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Month & Year of Passing
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__pgPassMonth"
                            required
                          >
                            {months(marksField("pass_month"))}
                          </Form.Control>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__pgPassYear"
                            required
                          >
                            {years(marksField("pass_year"))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            UG Register Number
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__pgRegisterNumber"
                            size="sm"
                            placeholder="Register Number"
                            defaultValue={marksField("register_no")}
                            //  onKeyPress={(e) => integerKeyPress(e)}
                            maxLength={15}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Details of Marks Obtained</h5>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <div className="table-responsive" id="part3mark">
                            <h3 style={{ color: "red" }}>
                              Part III Subject Calculation (Core & Allied
                              Subjects)
                            </h3>
                            <table className="table table-sm table-bordered hsc-mark-table">
                              <thead className="bg-light">
                                <tr>
                                  <th width="">Semester</th>
                                  <th>
                                    Marks Scored{" "}
                                    <span className="text-danger"> *</span>
                                  </th>
                                  <th>
                                    Maximum Marks{" "}
                                    <span className="text-danger"> *</span>
                                  </th>
                                  <th>Percentage of Marks</th>
                                  <th>Grade (Optional)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Semester 1{" "}
                                    <span className="text-danger"> *</span>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem1_mark_scored"
                                      id="sem1_mark_scored"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) => marksInputOnBlur1(e)}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      placeholder="Ex. 350"
                                      min={1}
                                      maxLength={4}
                                      required
                                      defaultValue={marksField(
                                        "sem1_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem1_max_scored"
                                      id="sem1_max_scored"
                                      placeholder="Ex. 400"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem1_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem1_max_scored",
                                          1,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem1_percentage"
                                      id="sem1_percentage"
                                      maxLength={4}
                                      placeholder="Ex. 87.50"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem1_percentage")}
                                      min={1}
                                      step="any"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      maxLength={4}
                                      size="sm"
                                      name="sem1_grade"
                                      placeholder="Ex. A+"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem1_grade")}
                                      min={1}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Semester 2{" "}
                                    <span className="text-danger"> *</span>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem2_mark_scored"
                                      id="sem2_mark_scored"
                                      placeholder="Ex. 350"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) =>
                                        marksInputOnBlur1("sem2_mark_scored", e)
                                      }
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      required
                                      defaultValue={marksField(
                                        "sem2_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem2_max_scored"
                                      id="sem2_max_scored"
                                      placeholder="Ex. 400"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem2_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem2_max_scored",
                                          2,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem2_percentage"
                                      id="sem2_percentage"
                                      placeholder="Ex. 87.50"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem2_percentage")}
                                      min={1}
                                      maxLength={4}
                                      step="any"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem2_grade"
                                      placeholder="Ex. A+"
                                      maxLength={4}
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem2_grade")}
                                      min={1}
                                    />
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    Semester 3{" "}
                                    <span className="text-danger"> *</span>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem3_mark_scored"
                                      id="sem3_mark_scored"
                                      placeholder="Ex. 350"
                                      maxLength={4}
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) =>
                                        marksInputOnBlur1("sem3_mark_scored", e)
                                      }
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      required
                                      defaultValue={marksField(
                                        "sem3_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem3_max_scored"
                                      id="sem3_max_scored"
                                      placeholder="Ex. 400"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem3_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem3_max_scored",
                                          3,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem3_percentage"
                                      id="sem3_percentage"
                                      placeholder="Ex. 87.50"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem3_percentage")}
                                      min={1}
                                      maxLength={4}
                                      step="any"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem3_grade"
                                      placeholder="Ex. A+"
                                      maxLength={4}
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem3_grade")}
                                      min={1}
                                    />
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    Semester 4{" "}
                                    <span className="text-danger"> *</span>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem4_mark_scored"
                                      id="sem4_mark_scored"
                                      placeholder="Ex. 350"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) =>
                                        marksInputOnBlur1("sem4_mark_scored", e)
                                      }
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      required
                                      defaultValue={marksField(
                                        "sem4_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem4_max_scored"
                                      id="sem4_max_scored"
                                      placeholder="Ex. 400"
                                      maxLength={4}
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem4_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem4_max_scored",
                                          4,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem4_percentage"
                                      id="sem4_percentage"
                                      placeholder="Ex. 87.50"
                                      maxLength={4}
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem4_percentage")}
                                      min={1}
                                      step="any"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem4_grade"
                                      placeholder="Ex. A+"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem4_grade")}
                                      maxLength={4}
                                      min={1}
                                    />
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    Semester 5{" "}
                                    <span className="text-danger"> *</span>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem5_mark_scored"
                                      id="sem5_mark_scored"
                                      placeholder="Ex. 350"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) =>
                                        marksInputOnBlur1("sem5_mark_scored", e)
                                      }
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      required
                                      defaultValue={marksField(
                                        "sem5_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem5_max_scored"
                                      id="sem5_max_scored"
                                      placeholder="Ex. 400"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem5_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      maxLength={4}
                                      min={1}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem5_max_scored",
                                          5,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem5_percentage"
                                      id="sem5_percentage"
                                      placeholder="Ex. 87.50"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem5_percentage")}
                                      min={1}
                                      maxLength={4}
                                      step="any"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem5_grade"
                                      placeholder="Ex. A+"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem5_grade")}
                                      min={1}
                                      maxLength={4}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Semester 6 </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem6_mark_scored"
                                      id="sem6_mark_scored"
                                      placeholder="Ex. 350"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      onBlur={(e) =>
                                        marksInputOnBlur1("sem6_mark_scored", e)
                                      }
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      defaultValue={marksField(
                                        "sem6_mark_scored"
                                      )}
                                      step=".01"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem6_max_scored"
                                      id="sem6_max_scored"
                                      placeholder="Ex. 400"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField(
                                        "sem6_max_scored"
                                      )}
                                      onKeyPress={(e) => integerKeyPress(e)}
                                      min={1}
                                      maxLength={4}
                                      onBlur={(e) =>
                                        maxMarksInputOnBlur1(
                                          "sem6_max_scored",
                                          6,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem6_percentage"
                                      id="sem6_percentage"
                                      placeholder="Ex. 87.50"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      value={marksField("sem6_percentage")}
                                      min={1}
                                      maxLength={4}
                                      step="any"
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      name="sem6_grade"
                                      placeholder="Ex. A+"
                                      className="cl__no__arrow i__mark_scored fw-bold"
                                      defaultValue={marksField("sem6_grade")}
                                      min={1}
                                      maxLength={4}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr className="bg-light">
                                  <td colSpan={2}>
                                    <Button
                                      type="button"
                                      variant="primary"
                                      id="part3submition"
                                      onClick={handleButtonClick}
                                      className="w-100"
                                    >
                                      {" "}
                                      Save Mark
                                    </Button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div
                            className="table-responsive"
                            id="subjectmark"
                            style={{ display: "none" }}
                          >
                            <Button
                              type="button"
                              id="editbtn"
                              variant="primary"
                              className="btn-sm"
                              style={{ float: "right" }}
                              onClick={handleEditClick}
                            >
                              Edit Part III Mark
                            </Button>
                            <table className="table table-sm table-bordered hsc-mark-table">
                              <thead
                                className="bg-light"
                                style={{ fontWeight: "bold" }}
                              >
                                <tr>
                                  <th width="200">Subject</th>
                                  <th>
                                    Marks Scored{" "}
                                    <span className="text-danger"> *</span>
                                  </th>
                                  <th>
                                    Maximum Marks{" "}
                                    <span className="text-danger"> *</span>
                                  </th>
                                  <th>Percentage of Marks</th>
                                  <th>No.of Attempts</th>
                                </tr>
                              </thead>
                              <tbody style={{ fontWeight: "bold" }}>
                                {marks.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {item.subjectname ==
                                        "Language (Part I)" ? (
                                          <>
                                            {item.subjectname}{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                            <Form.Control
                                              as="select"
                                              name="part1_language"
                                              defaultValue={marksField(
                                                "part1_language"
                                              )}
                                              size="sm"
                                              className="fw-bold form-select text-uppercase form-select-sm"
                                              required
                                            >
                                              <option value="">-Select-</option>
                                              <option
                                                value="Tamil"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Tamil"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Tamil
                                              </option>
                                              <option
                                                value="Hindi"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Hindi"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Hindi
                                              </option>
                                              <option
                                                value="Sanskrit"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Sanskrit"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Sanskrit
                                              </option>
                                              <option
                                                value="Malayalam"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Malayalam"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Malayalam
                                              </option>
                                              <option
                                                value="Telugu"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Telugu"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Telugu
                                              </option>
                                              <option
                                                value="French"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "French"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                French
                                              </option>
                                              <option
                                                value="Kannada"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Kannada"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Kannada
                                              </option>
                                              <option
                                                value="Arabic"
                                                selected={
                                                  marksField(
                                                    "part1_language"
                                                  ) == "Arabic"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Arabic
                                              </option>
                                            </Form.Control>{" "}
                                          </>
                                        ) : item.subjectname ==
                                          "Allied 1 (Part III - For reference only)" ? (
                                          <>
                                            {item.subjectname}{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                            <Form.Control
                                              as="select"
                                              name="part3_allied1"
                                              defaultValue={marksField(
                                                "part3_allied1"
                                              )}
                                              size="sm"
                                              className="fw-bold form-select form-select-sm text-uppercase"
                                              required
                                            >
                                              <option value="">-Select-</option>
                                              <option
                                                value="Mathematics"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Mathematics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Mathematics
                                              </option>
                                              <option
                                                value="Physics"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Physics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Physics
                                              </option>
                                              <option
                                                value="Chemistry"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Chemistry"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Chemistry
                                              </option>
                                              <option
                                                value="Botany"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Botany"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Botany
                                              </option>
                                              <option
                                                value="Zoology"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Zoology"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Zoology
                                              </option>
                                              <option
                                                value="Business Mathematics"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Business Mathematics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Business Mathematics
                                              </option>
                                              <option
                                                value="Other"
                                                selected={
                                                  marksField("part3_allied1") ==
                                                  "Other"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Other
                                              </option>
                                            </Form.Control>{" "}
                                          </>
                                        ) : item.subjectname ==
                                          "Allied 2 (Part III - For reference only)" ? (
                                          <>
                                            {item.subjectname}{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                            <Form.Control
                                              as="select"
                                              name="part3_allied2"
                                              defaultValue={marksField(
                                                "part3_allied2"
                                              )}
                                              size="sm"
                                              className="fw-bold form-select form-select-sm text-uppercase"
                                              required
                                            >
                                              <option value="">-Select-</option>
                                              <option
                                                value="Mathematics"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Mathematics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Mathematics
                                              </option>
                                              <option
                                                value="Physics"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Physics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Physics
                                              </option>
                                              <option
                                                value="Chemistry"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Chemistry"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Chemistry
                                              </option>
                                              <option
                                                value="Botany"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Botany"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Botany
                                              </option>
                                              <option
                                                value="Zoology"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Zoology"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Zoology
                                              </option>
                                              <option
                                                value="Business Mathematics"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Business Mathematics"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Business Mathematics
                                              </option>
                                              <option
                                                value="Other"
                                                selected={
                                                  marksField("part3_allied2") ==
                                                  "Other"
                                                    ? "selected"
                                                    : ""
                                                }
                                              >
                                                Other
                                              </option>
                                            </Form.Control>{" "}
                                          </>
                                        ) : item.subjectname ==
                                          "Major (Part III)" ? (
                                          <>
                                            {item.subjectname}{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </>
                                        ) : (
                                          item.subjectname
                                        )}
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          onBlur={(e) =>
                                            marksInputOnBlur(item, e)
                                          }
                                          onKeyPress={(e) => integerKeyPress(e)}
                                          min={1}
                                          maxLength={4}
                                          readOnly={
                                            item.subjectname ==
                                            "Major (Part III)"
                                              ? true
                                              : false
                                          }
                                          required={
                                            item.subject_id != 6 &&
                                            item.subject_id != 7
                                              ? true
                                              : false
                                          }
                                          defaultValue={item.mark_scored}
                                          step=".01"
                                          disabled={!item.subject_id}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          disabled={!item.subject_id}
                                          defaultValue={item.mark_maximum}
                                          min={1}
                                          maxLength={4}
                                          readOnly={
                                            item.subjectname ==
                                            "Major (Part III)"
                                              ? true
                                              : false
                                          }
                                          onBlur={(e) =>
                                            maxMarksInputOnBlur(item, e)
                                          }
                                          onKeyPress={(e) => integerKeyPress(e)}
                                          required={
                                            item.subject_id != 6 &&
                                            item.subject_id != 7
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          value={item.percentage_of_marks || ""}
                                          disabled={!item.subject_id}
                                          min={1}
                                          step="any"
                                          required={
                                            item.subject_id != 6 &&
                                            item.subject_id != 7
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          defaultValue={item.no_of_attempts}
                                          maxLength={4}
                                          disabled={!item.subject_id}
                                          min={1}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item,
                                              "no_of_attempts",
                                              e
                                            )
                                          }
                                          onKeyPress={(e) => integerKeyPress(e)}
                                          required={
                                            item.subject_id != 6 &&
                                            item.subject_id != 7
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot style={{ fontWeight: "bold" }}>
                                <tr className="bg-light">
                                  <td>Total</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="fw-bold"
                                      size="sm"
                                      name="scored_total"
                                      value={getTotalByFieldName("mark_scored")}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="fw-bold"
                                      size="sm"
                                      name="max_total"
                                      value={getTotalByFieldName(
                                        "mark_maximum"
                                      )}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      className="fw-bold"
                                      size="sm"
                                      name="overall_percentage"
                                      value={getOverallpercentage()}
                                      required
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <Row
                        className=" pb-2 border-bottom"
                        id="subjectmarktotal"
                        style={{ display: "none", fontWeight: "bold" }}
                      >
                        <Col md={3}>
                          <label>
                            Marks scored in Part III
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={2}>
                          MARKS SCORED
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_scored"
                            size="sm"
                            value={part3Total("mark_scored")}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          {" "}
                          MAXIMUM MARKS
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_total"
                            size="sm"
                            value={part3Total("mark_maximum")}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          {" "}
                          PERCENTAGE (%)
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_percentage"
                            size="sm"
                            value={part3Percentage("mark_maximum")}
                            required
                          />
                        </Col>
                      </Row>

                      {/*  {lowerCase(community) == "oc" && (
                        <input
                          type="hidden"
                          name="i__community"
                          value={lowerCase(community)}
                        />
                      )}

                      {lowerCase(community) != "oc" && (
                        <>
                          <Row className="context_box_title mt-2">
                            <Col md={12}>
                              <h5>Community certificate details</h5>
                            </Col>
                          </Row>
                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Community
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="i__community"
                                size="sm"
                                value={upperCase(community)}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Name of the Caste
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="i__caste"
                                size="sm"
                                defaultValue={applicant.caste}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Issuing Authority
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                as="select"
                                className="text-uppercase fw-bold"
                                name="i__issuingAuthority"
                                size="sm"
                                defaultValue={marksField("issuing_authority")}
                                required
                              >
                                <option value="">-Select-</option>
                                {COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY.map(
                                  (item) => (
                                    <option
                                      value={item}
                                      selected={
                                        item == marksField("issuing_authority")
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {item}
                                    </option>
                                  )
                                )}
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Certificate No
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="i__certificateNo"
                                defaultValue={marksField(
                                  "community_certificate_no"
                                )}
                                size="sm"
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Date of Issue
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="date"
                                className="text-uppercase fw-bold"
                                name="i__issueDate"
                                size="sm"
                                defaultValue={marksField(
                                  "community_certificate_issue_on"
                                )}
                                max={momentDate(new Date(), "YYYY-MM-DD")}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                District
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="i__district"
                                size="sm"
                                defaultValue={marksField("district")}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Taluk
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="i__taluk"
                                defaultValue={marksField("taluk")}
                                size="sm"
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )} */}

                      <Row className="mt-4">
                        {props.pageType && props.pageType == "edit" && (
                          <Col md={6}>
                            <Link to="/" className="btn btn-danger w-100">
                              Cancel
                            </Link>
                          </Col>
                        )}
                        <Col
                          md={
                            props.pageType && props.pageType == "edit" ? 6 : 12
                          }
                        >
                          <Button
                            type="submit"
                            id="savebtn"
                            variant="success"
                            className="w-100"
                            disabled={loader}
                          >
                            Save & Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            Please check all the filled informations before processing. Do you
            want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default StepTwo;
