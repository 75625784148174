import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row } from "react-bootstrap";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import { upperCase } from "../../utils";

const AdmissionInstructions = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [accept, setAccept] = useState(false);

  useEffect(() => {
    loadPage();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }, [props.match.params]);

  const loadPage = () => {
    setLoader(true);
    axios
      .get(
        ServiceUrl.ADMISSION.CUSTOM_PAGE + "?slug=" + props.match.params.pageId
      )
      .then((res) => {
        if (res["data"].status == "1") {
          setDataList(res["data"].data);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
  };

  const continueLink = () => {
    try {
      let pageId = props.match.params.pageId;
      let pageIdList = pageId.split("-");
      if (upperCase(pageIdList[0]) == "UG") return "/apply/ug";
      if (upperCase(pageIdList[0]) == "PG") return "/apply/pg";
      if (upperCase(pageIdList[0]) == "MPHIL") return "/apply/mphil";
    } catch (e) {}
  };

  return (
    <>
      <div className="container">
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Spin spinning={loader}>
                  <div style={{ minHeight: "250px" }} className="px-3">
                    {dataList && dataList.id && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dataList.content_html,
                          }}
                        />
                        <hr />
                        <div className="text-center fs-6 mb-4">
                          <input
                            type="checkbox"
                            checked={accept}
                            onChange={(e) => setAccept(!accept)}
                          />
                          <span> I read and accept the Guidelines</span>
                          {accept && (
                            <div>
                              <Link
                                to={continueLink()}
                                className="btn btn-primary btn-sm mt-3"
                              >
                                Continue
                                <i className="fa-solid fa-chevron-right px-2"></i>
                              </Link>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Spin>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(AdmissionInstructions);
