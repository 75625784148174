import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { Spin } from "antd";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import PsContext from "../../context";
import { queryParam, momentDate } from "../../utils";
import { ServiceUrl } from "../../utils/serviceUrl";

const RegisterSuccess = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const apiCode = params.get("api_code");

  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState([]);
  const [timeSpan, setTimeSpan] = useState(10);
  const [resend, setResend] = useState(false);
  const [toVerify, setToVerify] = useState(false);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    checkMobileVerified();
  }, []);

  const checkMobileVerified = () => {
    setLoader(true);
    let m = getToken("api_code");

    axios
      .get(ServiceUrl.ADMISSION.GET_PG_DETAILS + "?i__apiCode=" + apiCode)
      .then((res) => {
        if (res["data"].status == "0") {
          toast.error(res["data"].message || "Error");
        } else {
          setStatus(res["data"].status);
          if (res["data"].status == "1") setUser(res["data"].user);
        }
        setLoader(false);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.ADMISSION.VERIFY_MOBILE_OTP, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          toast.success(res["data"].message || "Verified");
          context.saveLogin(res["data"].user, res["data"].api, "", "");
          context.updateLogged();
          window.location.href = "";
          //setToVerify(true);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
  };

  const getToken = (fieldname) => {
    try {
      let s = queryParam(props.location.search);
      let m = [];
      if (s && s.token) m = context.decode(s.token);
      return m[fieldname];
    } catch (er) {}
  };

  const resendOTP = () => {
    setLoader(true);
    let m = getToken("api_code");
    const form = new FormData();
    form.append("i__api", m);
    axios.post(ServiceUrl.ADMISSION.RESEND_OTP, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");

        setMinutes(1);
        setSeconds(30);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  //if (toVerify) return <Redirect to={`/sign-in`} />;

  return (
    <>
      <div
        className="container mb-5"
        style={{ backgroundColor: "#FFF", height: "375px" }}
      >
        <div>
          <h3
            style={{
              textAlign: "center",
              backgroundColor: "#e19f00",
              color: "#a60000",
            }}
          >
            {" "}
            PG ADMISSIONS PORTAL {context.settingValue("current_academic_year")}
          </h3>
        </div>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Spin spinning={loader}>
              <h4
                style={{
                  color: "#000",
                  textAlign: "center",
                  padding: "20px 0 20px 0",
                }}
              >
                CONGRATULATIONS!
              </h4>
              <h4
                style={{
                  color: "#000",
                  textAlign: "center",
                  padding: "10px 0 20px 0",
                }}
              >
                THANK YOU FOR REGISTERING WITH ST.XAVIER'S COLLEGE.
              </h4>

              <h5
                style={{
                  color: "#000",
                  textAlign: "center",
                }}
              >
                USERNAME : {user && user.mobile}
                <br></br>
                PASSWORD :{" "}
                {user && momentDate(user.date_of_birth, "DD-MM-YYYY")}
              </h5>
              <br></br>
              <br></br>
              <p
                style={{
                  textAlign: "center",
                }}
                className="sussbtn"
              >
                <a href="https://sxcerp.in/sign-in?type=pg">
                  <Button type="submit" variant="success" className="w-100">
                    Click Here to Login
                  </Button>
                </a>
              </p>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RegisterSuccess;
