import React, { useContext, useEffect, useState } from "react";

import { Link, Redirect, withRouter } from "react-router-dom";

import { Spin } from "antd";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import PsContext from "../../context";
import { queryParam } from "../../utils";
import { ServiceUrl } from "../../utils/serviceUrl";

const MobileVerification = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(0);

  const [validated, setValidated] = useState(false);
  const [mobile, setMobile] = useState("");
  const [timeSpan, setTimeSpan] = useState(10);
  const [resend, setResend] = useState(false);
  const [toVerify, setToVerify] = useState(false);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [apicode, setApicode] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    checkMobileVerified();
  }, []);

  const checkMobileVerified = () => {
    setLoader(true);
    let m = getToken("api_code");

    axios
      .get(ServiceUrl.ADMISSION.CHECK_MOBILE_VERIFIED + "?i__apiCode=" + m)
      .then((res) => {
        if (res["data"].status == "0") {
          toast.error(res["data"].message || "Error");
        } else {
          setStatus(res["data"].status);
          if (res["data"].status == "1") setMobile(res["data"].data);
        }
        setLoader(false);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.ADMISSION.VERIFY_MOBILE_OTP, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          toast.success(res["data"].message || "Verified");
          if (res["data"].user.applied_for == "PG") {
            setApicode(res["data"].user.api_code);
            setShouldRedirect(true); // Trigger the redirect
          } else {
            context.saveLogin(res["data"].user, res["data"].api, "", "");
            context.updateLogged();
            window.location.href = "";
          }
          //setToVerify(true);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
  };

  if (apicode && shouldRedirect) {
    return <Redirect to={`/registration-success?api_code=${apicode}`} />;
  }

  const getToken = (fieldname) => {
    try {
      let s = queryParam(props.location.search);
      let m = [];
      if (s && s.token) m = context.decode(s.token);
      return m[fieldname];
    } catch (er) {}
  };

  const resendOTP = () => {
    setLoader(true);
    let m = getToken("api_code");
    const form = new FormData();
    form.append("i__api", m);
    axios.post(ServiceUrl.ADMISSION.RESEND_OTP, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");

        setMinutes(1);
        setSeconds(30);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  //if (toVerify) return <Redirect to={`/sign-in`} />;

  return (
    <>
      <div className="container mt-4 mb-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Spin spinning={loader}>
              {status == "1" && (
                <Card>
                  <Card.Body>
                    <h5 className="text-center mt-2 mb-4">
                      Mobile Verification
                    </h5>
                    <Form
                      action=""
                      method="post"
                      noValidate
                      validated={validated}
                      onSubmit={handleFormSubmit}
                    >
                      <input
                        type="hidden"
                        name="i__api"
                        value={getToken("api_code")}
                      />
                      <Row>
                        <Col md={12}>
                          <Form.Control
                            ttype="text"
                            className="fw-bold text-center bg-light fs-5"
                            size="lg"
                            name="i__mobile"
                            value={mobile}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <label>
                            Enter your otp{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            ttype="number"
                            className="fw-bold text-center  fs-5"
                            size="lg"
                            name="i__otp"
                            placeholder="OTP"
                            autoFocus={true}
                            required
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <Button
                            type="submit"
                            variant="success"
                            className="w-100"
                          >
                            Verify OTP
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <div className="text-center my-4">
                      {seconds > 0 || minutes > 0 ? (
                        <span className="float-start">
                          Time Remaining:{" "}
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      ) : (
                        <span className="float-start">
                          Didn't recieve code?
                        </span>
                      )}
                      {seconds > 0 || minutes > 0 ? (
                        <span className="float-end">Resend OTP</span>
                      ) : (
                        <a
                          className="float-end"
                          style={{
                            color: seconds > 0 || minutes > 0 ? "" : "",
                          }}
                          onClick={(e) => resendOTP()}
                        >
                          Resend OTP
                        </a>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              )}

              {status == "2" && (
                <Card className="card-border-btm">
                  <Card.Body className="fs-6 text-center py-4">
                    <div className="alert alert-danger">
                      Sorry the given mobile number is already verified.
                    </div>
                    Please <Link to="/sign-in">Click Here</Link> to login your
                    account.
                  </Card.Body>
                </Card>
              )}
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MobileVerification;
