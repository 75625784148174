import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";

import { baseUrl } from "../../../utils";
import Personal from "../applicationPreview/personal";
import FamilyDetails from "../applicationPreview/familyDetails";
import SpecialReservation from "../applicationPreview/specialReservation";
import CommunicationDetails from "../applicationPreview/communicationDetails";
import QualificationDetails from "../applicationPreview/qualificationDetails";
import CertificateDetails from "../applicationPreview/certificateDetails";

const MyProfile = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [applicant, setApplicant] = useState([]);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    loadApplicantInfo();
    loadData();
  }, []);

  const loadData = () => {
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.PG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        setBaisc(res["data"].data.basic);
        setMarks(res["data"].data.marks);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.PG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropgation();
      setValidated(true);
      return;
    }

    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    axios.post(ServiceUrl.PG.UPDATE_DECLARATION, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        props.history.push("/apply");
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
        setLoader(false);
      }
    });
  };

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Spin spinning={loader}>
              <Row>
                <Col md={12}>
                  <div className="text-center mb-4">
                    <h5>My Profile</h5>
                  </div>
                </Col>
              </Row>
              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="context_box_title mb-3">
                    <Col md={12}>
                      <h5>Personal Details</h5>
                    </Col>
                  </Row>

                  <Personal data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Family Details </h5>
                    </Col>
                  </Row>

                  <FamilyDetails data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Special Reservation </h5>
                    </Col>
                  </Row>

                  <SpecialReservation data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Communication Details </h5>
                    </Col>
                  </Row>

                  <CommunicationDetails data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Details of Qualification</h5>
                    </Col>
                  </Row>

                  <QualificationDetails data={marks} personal={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Certificates</h5>
                    </Col>
                  </Row>

                  <CertificateDetails data={basic} />
                  <hr />
                  <Row className="mt-3">
                    <Col md={11} className="text-end">
                      Signature <br />
                      <img
                        src={
                          baseUrl +
                          ServiceUrl.PG.VIEW_CERTIFICATE +
                          "?api_code=" +
                          context.user.api_code +
                          "&field_name=signature"
                        }
                        style={{
                          maxHeight: "50px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col md={12} className="text-center">
                      <Link to="/" className="btn btn-primary btn-sm">
                        <i className="fa-solid fa-house me-2"></i>Go to
                        dashboard
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyProfile;
