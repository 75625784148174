import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { toast } from "react-hot-toast";
import {
  baseUrl,
  capitalizeFirst,
  groupByMultiple,
  upperCase,
} from "../../../utils";
import { Spin } from "antd";
import { ServiceUrl } from "../../../utils/serviceUrl";
import axios from "axios";
import { Button, Card } from "react-bootstrap";

const ApplicationList = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataView, setDataView] = useState([]);

  const [paymentLink, setPaymentLink] = useState([]);

  useEffect(() => {
    loadApplicationList();
  }, []);

  const loadApplicationList = () => {
    try {
      context.setLoader(true);
      var form = new FormData();
      form.append("i__apiCode", context.user.api_code);
      axios.post(ServiceUrl.PG.APPLIED_COURSES, form).then((res) => {
        if (res["data"].status == "1") {
          var d = groupByMultiple(res["data"].data, function (obj) {
            return [obj.uuid, obj.course_type];
          });
          setDataList(res["data"].data);
          setDataView(d);
        } else {
          toast.error(res["data"].message || "Error");
        }
        context.setLoader(false);
      });
    } catch (error) {
      context.setLoader(false);
    }
  };

  const listCourses = (items) => {
    let rv = [];
    items.map((item) =>
      rv.push(
        <li>
          {item.degreename}-{item.coursename}
        </li>
      )
    );
    return <ul>{rv}</ul>;
  };

  const repayClick = (item) => {
    if (!window.confirm("Do you want to repay?")) return;
    context.setLoader(true);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    form.append("uuid", item.uuid);
    axios.post(ServiceUrl.PG.REPAY_LINK, form).then((res) => {
      if (res["data"].status == "1") {
        setPaymentLink(res["data"].data);

        document.getElementById("redirect").submit();
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
      }
    });
  };

  const downloadFile = (item) => {
    context.setLoader(true);
    var filePath =
      baseUrl +
      ServiceUrl.PG.APPLICATION_DOWNLOAD_LINK +
      item.admission_year +
      "/" +
      item.uuid +
      "/" +
      item.application_code;

    var a = document.createElement("A");
    a.href = filePath;
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    context.setLoader(false);
  };

  const trackMyOrderStatus = (course) => {
    try {
      context.setLoader(true);
      setLoader(true);
      var form = new FormData();
      form.append("i__apiCode", course.api_code);
      form.append("uuid", course.uuid);
      form.append("id", course.id);

      axios.post(ServiceUrl.PG.TRACK_MY_ORDER, form).then((res) => {
        if (res["data"].status == "1") {
          loadApplicationList();
        } else {
          toast.error(res["data"].message, "error");
        }
        setLoader(false);
        context.setLoader(false);
      });
    } catch (error) {
      setLoader(false);
      context.setLoader(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="fw-bold">Applied Courses</Card.Header>
        <Card.Body>
          <Spin spinning={loader}>
            <div className="table-responsive">
              <table className="table table-sm table-bordered">
                <thead>
                  <tr className="bg-light">
                    <th width="120">Type</th>
                    <th>App.No</th>
                    <th>Course Name</th>
                    <th width="200">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataView.map((items, i) => {
                    let item = items[0];
                    return (
                      <tr key={i}>
                        <td>
                          {upperCase(item.course_type) == "SELF"
                            ? "Self Financed"
                            : "Aided"}
                        </td>
                        <td>{item.application_code}</td>
                        <td>
                          {listCourses(items)}
                          {item.rejected_by && (
                            <span className="text-danger">
                              Rejeced: {item.rejected_reason}
                            </span>
                          )}
                        </td>
                        <td align="center">
                          {item.fee_paid_status == "1" ? (
                            <>
                              <Button
                                type="button"
                                size="xs"
                                variant="success"
                                onClick={(e) => downloadFile(item)}
                              >
                                <i className="fa-solid fa-download me-2"></i>
                                Download
                              </Button>
                              <br />
                              {item.rejected_by ? (
                                ""
                              ) : (
                                <span className="text-success fw-bold">
                                  Paid
                                </span>
                              )}
                            </>
                          ) : item.payment_method == "direct" &&
                            item.fee_paid_status == "0" ? (
                            <>
                              <span className="text-danger">
                                Your community certificate will be verified by
                                the college office. Wait for 24 hours.
                              </span>
                            </>
                          ) : (
                            <Button
                              type="button"
                              size="sm"
                              onClick={(e) => repayClick(item)}
                            >
                              Rs.{item.total_fee} - Pay Now
                            </Button>
                          )}
                          {item.fee_paid_status == "0" &&
                            item.order_id != null &&
                            upperCase(item.fee_paid_response) !=
                              upperCase("Aborted") && (
                              <div className="my-2">
                                <a
                                  className=" text-primary"
                                  onClick={(e) => trackMyOrderStatus(item)}
                                >
                                  Track Status
                                </a>
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spin>
        </Card.Body>
      </Card>

      {paymentLink && paymentLink.access_code && (
        <form
          method="post"
          name="redirect"
          id="redirect"
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        >
          <input type="hidden" name="encRequest" value={paymentLink.reqdata} />
          <input
            type="hidden"
            name="access_code"
            value={paymentLink.access_code}
          />
        </form>
      )}
    </>
  );
};

export default ApplicationList;
