import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import {
  baseUrl,
  capitalizeFirst,
  lowerCase,
  momentDate,
  upperCase,
} from "../../../utils";
import { ServiceUrl } from "../../../utils/serviceUrl";

const Personal = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <InputField
            label="Name"
            value={field("name") + " " + field("initial")}
            firstMd={4}
            secondMd={8}
          />
          <InputField
            label="Gender"
            value={capitalizeFirst(field("gender"))}
            firstMd={4}
            secondMd={8}
          />

          <InputField
            label="Date of Birth"
            value={momentDate(field("date_of_birth"), "DD/MMM/YYYY")}
            firstMd={4}
            secondMd={8}
          />

          <InputField
            label="Board"
            value={upperCase(field("board"))}
            firstMd={4}
            secondMd={8}
          />

          <InputField
            label="Candidate Mobile"
            value={upperCase(field("mobile"))}
            firstMd={4}
            secondMd={8}
          />

          {/*<InputField
            label="Year of Passing"
            value={upperCase(field("year_of_passing"))}
            firstMd={4}
            secondMd={8}
  />*/}
        </Col>

        <Col md={3}>
          <img
            src={
              baseUrl +
              ServiceUrl.UG.VIEW_CERTIFICATE +
              "?api_code=" +
              field("api_code") +
              "&field_name=profile_photo"
            }
            className="border"
            style={{
              maxWidth: "100%",
              maxHeight: "180px",
            }}
          />
        </Col>
      </Row>

      {/*<InputField label="Candidate's Mobile No" value={field("mobile")} />*/}

      <InputField label="Candidate's  Email Id" value={field("email")} />

      <InputField label="Candidate's  Aadhar No" value={field("aadhar")} />

      {field("board") == "state" && (
        <InputField label="EMIS No" value={field("emisno")} />
      )}

      <InputField label="ABC Id" value={field("abc_id")} />

      <InputField
        label="Community / Caste"
        value={
          upperCase(field("community")) +
          " - " +
          capitalizeFirst(field("caste"))
        }
      />
      {(lowerCase(field("community")) != "oc" || field("is_ews") == "1") && (
        <>
          <InputField
            label="Community Certificate Number"
            value={field("community_certificate_no")}
          />
          <InputField
            label="Community Certificate Issued Date"
            value={field("community_certificate_issue_on")}
          />
        </>
      )}

      <InputField
        label="Religion"
        value={
          lowerCase(field("religion")) == "christian"
            ? `${capitalizeFirst(field("religion"))} / ${upperCase(
                field("denomination")
              )}`
            : capitalizeFirst(field("religion"))
        }
      />

      {lowerCase(field("religion")) == "christian" &&
        context.settingValue("fiele_ug_dalit_required") == "1" &&
        upperCase(field("denomination")) ==
          upperCase(context.settingValue("clg_admission_denomination")) && (
          <InputField
            label="Dalit"
            value={upperCase(field("is_dalit")) == "1" ? "Yes" : "No"}
          />
        )}

      <InputField label="Blood Group" value={upperCase(field("blood_group"))} />

      <InputField
        label="Mother Tongue"
        value={capitalizeFirst(field("mother_tongue"))}
      />

      <InputField
        label="Nationality"
        value={capitalizeFirst(field("nationality"))}
      />
    </>
  );
};

export default Personal;
