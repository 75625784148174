import React, { useState, useContext, useEffect } from "react";

import { Link, Redirect, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import {
  integerIndMobile,
  lettersOnly,
  lowerCase,
  momentDate,
  upperCase,
} from "../../utils";
import { LOGIN_FOR } from "../../utils/data";
import PsModalWindow from "../../utils/PsModalWindow";
import MobileNumberExistAlertMessage from "./mobileNumberExistAlertMessage";

const Registration = (props) => {
  const context = useContext(PsContext);

  let courseType = props.match.params.courseType;

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captcha, setCaptcha] = useState([]);
  const [showMobileExitAlert, setShowMobileExistAlert] = useState(false);
  const [toVerify, setToVerify] = useState(false);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    setLoader(true);
    axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.ADMISSION.REGISTER, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          setToVerify(res["data"].token);
        } else {
          if (
            upperCase(res["data"].message) ==
            upperCase("Mobile number already exist")
          )
            setShowMobileExistAlert(true);
          else toast.error(res["data"].message || "Error");

          loadCaptcha();
        }
        setLoader(false);
      });
  };

  const maxDate = () => {
    let y = momentDate(new Date(), "YYYY") - 16;
    let d = momentDate(new Date(), "DD");
    let m = momentDate(new Date(), "MM");
    return momentDate(y + "-" + m + "-" + d, "YYYY-MM-DD");
  };

  const applyForValue = () => {
    try {
      let courseType = props.match.params.courseType;
      let s = LOGIN_FOR.find((item) => item.value == lowerCase(courseType));

      if (context.settingValue(s.key) == "1") return upperCase(s.value);

      return "";
    } catch (e) {
      return "";
    }
  };

  if (!courseType) return <></>;
  if (toVerify)
    return <Redirect to={`/mobile-verification?token=${toVerify}`} />;

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Body>
                <h5 className="text-center mt-2 mb-4">NEW REGISTRATION</h5>
                <Spin spinning={loader}>
                  <Form
                    action=""
                    method="post"
                    noValidate
                    validated={validated}
                    onSubmit={handleFormSubmit}
                  >
                    <input
                      type="hidden"
                      name="academic_year"
                      value={context.settingValue("current_academic_year")}
                    />
                    <input
                      type="hidden"
                      name="i__captchaCode"
                      value={captcha.code}
                    />

                    <Row>
                      <Col md={12}>
                        <label>
                          Apply for <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__applyFor"
                          className="fw-bold"
                          value={applyForValue()}
                          required
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={3}>
                        <label>
                          Initial <span className="text-danger"></span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__initial"
                          className="fw-bold text-uppercase"
                          placeholder="Initial"
                          onKeyPress={lettersOnly}
                        />
                      </Col>
                      <Col md={9}>
                        <label>
                          Name of the candidate{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__name"
                          className="fw-bold text-uppercase"
                          placeholder="Name of the Candidate"
                          onKeyPress={lettersOnly}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Candidate name is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate's mobile number
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          name="i__mobile"
                          className="fw-bold"
                          placeholder="Candidate mobile number"
                          onKeyPress={integerIndMobile}
                          onWheel={(e) => e.target.blur()}
                          required
                        />
                        <label style={{ fontSize: "11px" }}>
                          ஒரு தொலைபேசி எண்ணை ஒரு முறை மட்டுமே பதிவு செய்ய
                          இயலும்.
                        </label>
                        <Form.Control.Feedback type="invalid">
                          Candidate's mobile number is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate's Mail id
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="email"
                          name="i__email"
                          className="fw-bold"
                          placeholder="Candidate Mail id"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Candidate's mail id is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate's Date of Birth
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="date"
                          name="i__dob"
                          className="fw-bold"
                          max={maxDate()}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Candidate's date of birth is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={5} xs={12} sm={12}>
                        <img src={captcha && captcha.img} />
                        <Button
                          type="button"
                          className="ms-2"
                          variant="white border"
                          onClick={(e) => loadCaptcha()}
                        >
                          <i className="fa-solid fa-arrow-rotate-right"></i>
                        </Button>
                      </Col>
                      <Col md={7} xs={12} sm={12}>
                        <Form.Control
                          type="text"
                          name="i__captcha"
                          required
                          className="fw-bold"
                          placeholder="Enter captcha code"
                        />
                        <Form.Control.Feedback type="invalid">
                          Captcha code is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={12}>
                        <Button
                          type="submit"
                          variant="success"
                          className="w-100"
                        >
                          Register Now
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Spin>

                <div className="text-center my-4 fs-6">
                  Already registered?{" "}
                  <Link to="/sign-in" className="">
                    Sign In
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <PsModalWindow
        show={showMobileExitAlert}
        onHide={(e) => setShowMobileExistAlert(false)}
        size="md"
        title="Mobile number exist"
      >
        <MobileNumberExistAlertMessage />
      </PsModalWindow>
    </>
  );
};

export default Registration;
