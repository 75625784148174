import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { upperCase } from "../../../utils";

const FamilyDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <InputField
        label="Parental Status"
        value={upperCase(field("parental_status"))}
      />
      <InputField
        label="Father's Name"
        value={upperCase(field("father_name"))}
      />
      {field("father_status") == "1" && (
        <>
          <InputField
            label="Father's Occupation Type"
            value={upperCase(field("father_work_nature"))}
          />

          <InputField
            label="Father's Occupation"
            value={upperCase(field("father_occupation"))}
          />

          <InputField
            label="Father's Mobile"
            value={upperCase(field("father_phone"))}
          />
        </>
      )}
      <InputField
        label="Mother's Name"
        value={upperCase(field("mother_name"))}
      />
      {field("mother_status") == "1" && (
        <>
          <InputField
            label="Mother's Occupation Type"
            value={upperCase(field("mother_work_nature"))}
          />

          <InputField
            label="Mother's Occupation"
            value={upperCase(field("mother_occupation"))}
          />

          <InputField
            label="Mother's Mobile"
            value={upperCase(field("mother_phone"))}
          />
        </>
      )}
      <InputField
        label="Family Annual Income"
        value={upperCase(field("annul_income_range"))}
      />{" "}
    </>
  );
};

export default FamilyDetails;
