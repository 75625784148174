import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { VENDOR_LOGO } from "../../../utils/data";
import { Col, Row } from "react-bootstrap";
import { brandLogoWide } from "../../../utils";

const Header = (props) => {
  const context = useContext(PsContext);
  let text = window.location.href;
  // let position = text.search("admission");

  return (
    <>
      <header className="header bg-white shadow fixed-top">
        <div className=" header-top">
          <div className="container py-1">
            <Row>
              <Col md={6} className="d-none d-sm-block">
                <a className="text-white">
                  Online admission for the academic year{" "}
                  {context.settingValue("current_academic_year")}
                </a>
              </Col>
              <Col md={6} className="text-end">
                <a className="text-white">
                  <i className="fa-solid fa-phone me-2"></i>{" "}
                  {context.settingValue("admission_support_mobile")},
                  <i className="fa-solid fa-envelope mx-2"></i>
                  {context.settingValue("admission_support_email")}
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container py-2">
          <Row>
            <Col md={4}>
              <Link to="/" className="logo">
                <img
                  src={brandLogoWide()}
                  alt=""
                  style={{ maxWidth: "100%", maxHeight: "55px" }}
                />
              </Link>
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="d-none d-sm-block">
              <div className="text-end pt-2">
                <Link
                  to="/sign-in"
                  className="fs-6 border-end btn btn-trnasparent  pe-3"
                >
                  SIGN IN
                </Link>
                <Link
                  to="/sign-up"
                  className=" ms-3 btn btn-primary btn-sm rounded-pill px-3"
                >
                  NEW REGISTRATION
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </header>
    </>
  );
};

export default Header;
