import Ract from "react";
import Axios from "axios";
import toast from "react-hot-toast";
import { ServiceUrl } from "../utils/serviceUrl";

export const listAll = async () => {
  return new Promise((resolve, reject) => {
    Axios.get(ServiceUrl.ADMIN_SETTINGS.LIST)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const listSettingsFields = async (encode = false) => {
  return new Promise((resolve, reject) => {
    let url = ServiceUrl.ADMISSION.SETTINGS;
    if (encode) url += "?sta=1&admission=1";
    Axios.get(url)
      .then(function ({ data }) {
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const listAllDisabilityCategory = async () => {
  return new Promise((resolve, reject) => {
    let url = ServiceUrl.ADMISSION.DISABILITY_LIST;
    Axios.get(url)
      .then(function ({ data }) {
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const listUniversity = async () => {
  return new Promise((resolve, reject) => {
    let url = ServiceUrl.ADMISSION.UNIVERSITYLIST;
    Axios.get(url)
      .then(function ({ data }) {
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};
